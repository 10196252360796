<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="auto">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="12" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="3" md="1" class="mt-5">
              <span class="label-outlined">Início</span>
              <v-text-field solo label="Início" v-model="startDay" @change="getItems" />
            </v-col>

            <v-col cols="3" md="1" class="mt-5">
              <span class="label-outlined">Fim</span>
              <v-text-field solo label="Final" v-model="endDay" @change="getItems" />
            </v-col>

            <v-col cols="6" md="4" class="mt-5">
              <span class="label-outlined">Mês</span>
              <v-select
                solo
                v-model="month"
                :items="months"
                item-value="id"
                item-text="name"
                label="Mês"
                @change="getItems"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="customers"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          :header-props="headerProps"
        >
          <template v-slot:[`item.person.birthDate`]="{ item }">
            <span> {{ formatDate(item.person.birthDate) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="`/members/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="overline">
              Endereço: {{ getAddress(item.address) }}<br />
              Gênero: {{ getGender(item.person.gender) }}<br />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";
import * as moment from "moment";
export default Vue.extend({
  name: "Company",
  directives: { money: VMoney },
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Aniversariantes",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Membros",
        disabled: false,
        href: "/members",
      },
      {
        text: "Aniversariantes",
        disabled: true,
        href: "/members",
      },
    ],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    headerProps: {
      sortByText: "Ordenar por",
    },
    startDate: moment().startOf("month").format("MM-DD"),
    endDate: moment().endOf("month").format("MM-DD"),
    startDay: moment().startOf("month").format("DD"),
    endDay: moment().endOf("month").format("DD"),
    month: moment().startOf("month").format("MM"),
    id: "",
    openDialog: false,
    customers: [],
    expanded: [],
    singleExpand: false,
    headers: [
      {
        text: "Nascimento",
        align: "start",
        value: "person.birthDate",
        filtering: true,
      },
      {
        text: "Nome/Razão Social",
        align: "start",
        value: "person.corporateName",
        filtering: true,
      },
      {
        text: "Celular",
        align: "start",
        value: "contact.phoneMobile",
        filtering: true,
      },
      {
        text: "Email",
        align: "start",
        value: "contact.email",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    months: [
      {
        id: "01",
        name: "Janeiro",
      },
      {
        id: "02",
        name: "Fevereiro",
      },
      {
        id: "03",
        name: "Março",
      },
      {
        id: "04",
        name: "Abril",
      },
      {
        id: "05",
        name: "Maio",
      },
      {
        id: "06",
        name: "Junho",
      },
      {
        id: "07",
        name: "Julho",
      },
      {
        id: "08",
        name: "Agosto",
      },
      {
        id: "09",
        name: "Setembro",
      },
      {
        id: "10",
        name: "Outubro",
      },
      {
        id: "11",
        name: "Novembro",
      },
      {
        id: "12",
        name: "Dezembro",
      },
    ],
  }),
  methods: {
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    getGender(gender) {
      switch (gender) {
        case "M":
          return "MASCULINO";
        case "F":
          return "FEMININO";
        default:
          return "NÃO INFORMADO";
      }
    },
    getAddress(address) {
      if (!address.street) return "Endereço não cadastrado";
      return `${address.street}, ${address.number} - ${address.complement} ${address.zone} - ${address.city}/${address.state}`;
    },
    formatDate(input) {
      return moment(input).format("DD/MM/YYYY");
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions
          ?.map((a) => a.id)
          ?.includes("gerentor.person.customers.delete_one")
      ) {
        return true;
      }
      return false;
    },
    getItems() {
      if (this.endDay >= "28") {
        const end = moment(this.month).endOf("month").format("DD");
        this.endDay = end;
      }
      this.loading = true;
      http
        .get(
          `gerentor/customers/report/birthdays?startDate=${this.startDay}&endDate=${this.endDay}&month=${this.month}`
        )
        .then((data) => {
          this.customers = data.data;
          this.loading = false;
        });
      this.loading = false;
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>

<style>
.label-outlined {
  font-size: 12px;
  color: grey;
}
</style>
